const SimileBooster = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    viewBox="0 0 44 44"
  >
    <rect width={44} height={44} fill="#F3E9FF" rx={22} />
    <path
      fill="#8000FF"
      d="M22 10a12 12 0 1 0 0 24 12 12 0 0 0 0-24Zm-6 10.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 8.5a4 4 0 0 1-4-4h2a2 2 0 0 0 4 0h2a4 4 0 0 1-4 4Zm4.5-7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
    />
  </svg>
);
export default SimileBooster;
