const AuthorizedResellerBooster = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none">
    <rect width={40} height={40} fill="#D6F8FF" rx={20} />
    <path
      fill="#00C4EB"
      d="M29.167 18.333c.46 0 .833-.372.833-.833v-4.167a.833.833 0 0 0-.833-.833H10.833a.833.833 0 0 0-.833.833V17.5c0 .46.373.833.833.833.92 0 1.667.748 1.667 1.667 0 .92-.748 1.667-1.667 1.667A.833.833 0 0 0 10 22.5v4.167c0 .46.373.833.833.833h18.334c.46 0 .833-.372.833-.833V22.5a.833.833 0 0 0-.833-.833c-.92 0-1.667-.748-1.667-1.667 0-.92.747-1.667 1.667-1.667Zm-5 4.167h-8.334v-1.667h8.334V22.5Zm0-3.333h-8.334V17.5h8.334v1.667Z"
    />
  </svg>
);
export default AuthorizedResellerBooster;
