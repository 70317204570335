const HelpCenterBooster = () => (
  <svg
    width="42"
    height="44"
    viewBox="0 0 42 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="42" height="44" rx="21" fill="#FFE6F2" />
    <path
      d="M21 10C14.935 10 10 14.935 10 21V27C10 28.654 11.346 30 13 30H16C16.552 30 17 29.552 17 29V22C17 21.448 16.552 21 16 21H12C12 16.038 16.038 12 21 12C25.962 12 30 16.038 30 21H26C25.448 21 25 21.448 25 22V29C25 29.552 25.448 30 26 30H29C29.352 30 29.686 29.928 30 29.816C30 31.261 29.004 32 28 32H23C22.448 32 22 32.448 22 33C22 33.552 22.448 34 23 34H28C30.206 34 32 32.206 32 30V21C32 14.935 27.065 10 21 10Z"
      fill="#FF007A"
    />
  </svg>
);
export default HelpCenterBooster;
