const FreeCancellationBooster = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none">
    <rect width={40} height={40} fill="#DEFFEE" rx={20} />
    <path
      fill="#15D676"
      d="m27.668 11.532-7.5-1.5a.828.828 0 0 0-.327 0l-7.5 1.5a.833.833 0 0 0-.673.817v8.484c0 6.666 8.333 9.166 8.333 9.166s8.334-2.5 8.334-9.166v-8.484a.833.833 0 0 0-.667-.817Zm-2.867 5.677-5 5.833a.832.832 0 0 1-.601.29h-.033a.832.832 0 0 1-.589-.244l-2.5-2.5a.832.832 0 1 1 1.178-1.179l1.864 1.864 4.414-5.15a.834.834 0 0 1 1.265 1.085h.002Z"
    />
  </svg>
);
export default FreeCancellationBooster;
