export const BOOKING_FLOW_TYPE = {
  SEATMAP: 'SEATMAP',
  SVG: 'SVG',
  NORMAL: 'NORMAL',
  COMBO: 'COMBO',
  RESERVATION: 'RESERVE',
  PRIVATE_AIRPORT_TRANSFER: 'PRIVATE_AIRPORT_TRANSFER',
  SHARED_AIRPORT_TRANSFER: 'SHARED_AIRPORT_TRANSFER',
  SEATMAP_IFRAME: 'SEATMAP_IFRAME',
  HOP_ON_HOP_OFF: 'HOP_ON_HOP_OFF',
  PROPERTY_SELECTION: 'PROPERTY_SELECTION',
  GUIDED_TOUR_PROPERTY_SELECTION: 'GUIDED_TOUR_PROPERTY_SELECTION',
} as const;

export const BOOKING_FLOW_STAGE = {
  SELECT: 'select',
  CHECKOUT: 'checkout',
  /* SVG FLOW */
  SVG_SELECT: 'svg-select',
  SVG_VARIANT: 'svg-variant',
  /* SEATMAP FLOW */
  EXTERNAL_SEATMAP: 'seatmap-iframe-select',
  SEATMAP_SELECT: 'seatmap-select',
  SEATMAP_VARIANT: 'seatmap-variant',
  SEATMAP_CHECKOUT: 'seatmap-checkout',
  /* POST-CHECKOUT */
  MICROAUTH: 'payment-verification',
  CONFIRMATION: 'confirmation',
};
