const AuthorisedBooster = () => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.18182L5.2987 0L10.5974 2.18182V5.45455C10.5974 8.48182 8.33662 11.3127 5.2987 12C2.26078 11.3127 0 8.48182 0 5.45455V2.18182ZM6.29514 4.27511L8.0321 4.50896H8.03259C8.07785 4.51509 8.12035 4.53282 8.15531 4.56014C8.19027 4.58747 8.21628 4.62331 8.23041 4.66361C8.24455 4.70391 8.24624 4.74706 8.2353 4.78821C8.22435 4.82935 8.20121 4.86684 8.16849 4.89644L6.91133 6.03139L7.2079 7.63427C7.21563 7.67606 7.21058 7.71903 7.19333 7.7583C7.17608 7.79758 7.14732 7.8316 7.11029 7.85652C7.07326 7.88144 7.02944 7.89627 6.98379 7.89932C6.93814 7.90237 6.89248 7.89353 6.85197 7.8738L5.29849 7.11725L3.74502 7.8738C3.70451 7.89353 3.65885 7.90237 3.6132 7.89932C3.56755 7.89627 3.52373 7.88144 3.4867 7.85652C3.44967 7.8316 3.42091 7.79758 3.40366 7.7583C3.38641 7.71903 3.38136 7.67606 3.38909 7.63427L3.68566 6.03139L2.4285 4.89644C2.39577 4.86681 2.37263 4.82928 2.36171 4.78811C2.3508 4.74693 2.35253 4.70375 2.36672 4.66343C2.38091 4.62312 2.40699 4.58729 2.44201 4.55999C2.47703 4.5327 2.51959 4.51502 2.56489 4.50896L4.30185 4.27511L5.0792 2.8179C5.10175 2.78242 5.13392 2.753 5.17251 2.73257C5.2111 2.71213 5.25478 2.70138 5.29923 2.70138C5.34368 2.70138 5.38736 2.71213 5.42595 2.73257C5.46454 2.753 5.49671 2.78242 5.51926 2.8179L6.29514 4.27511Z"
      fill="black"
    />
  </svg>
);
export default AuthorisedBooster;
