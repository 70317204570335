export const headingsToRemove = [
  'Itinerary',
  'Itinerario',
  'Itinéraire',
  'Programma',
  'Ablauf',
  'Itinerário',
  'Routebeschrijving',
  'Route Details',
  'Detalles de la ruta',
  "Détails de l'itinéraire",
  "Dettagli dell'itinerario",
  'Details zur Route',
  'Detalhes do itinerário',
  'Route-informatie',
  'Boarding points',
  'Puntos de embarque',
  "Points d'embarquement",
  'Punti di imbarco',
  'Einstiegspunkte',
  'Pontos de embarque',
  'Instappunten',
];
