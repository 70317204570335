const StarHalfNew = ({ fillColor }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill={fillColor}
      d="M7 .512V11.27l-4.024 2.218.768-4.694L.49 5.472l4.5-.69L7 .513z"
    ></path>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M7 0c.186 0 .356.11.438.285l1.898 4.03 4.246.65a.495.495 0 01.394.349.529.529 0 01-.124.524l-3.071 3.135.724 4.429a.523.523 0 01-.194.5.47.47 0 01-.514.04L7 11.848l-3.797 2.092a.47.47 0 01-.514-.039.523.523 0 01-.195-.5l.725-4.429L.148 5.838a.529.529 0 01-.124-.524.495.495 0 01.394-.349l4.245-.65 1.9-4.03A.487.487 0 017 0zm0 1.668L5.426 5.009a.49.49 0 01-.367.28l-3.521.54 2.547 2.599a.527.527 0 01.14.453l-.6 3.672 3.148-1.735a.469.469 0 01.454 0l3.149 1.735-.602-3.672a.527.527 0 01.141-.453l2.547-2.6-3.52-.54a.49.49 0 01-.368-.279L7 1.668z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default StarHalfNew;
